.panel {
    border: 1px solid $color-lightgray;
    border-radius: 4px;

    &__head {
        @mixin gradient-darken bottom, $color-white, 3%;

        position: relative;
        display: block;
        padding: 20px 24px;
        border-radius: 4px 4px 0 0;
        text-decoration: none;
        color: $color-lightblack;

        &::after {
            display: none;
            content: '';
            position: absolute;
            right: 24px;
            top: 50%;
            width: 24px;
            height: 24px;
            margin-top: -12px;
            background-position: 50% 50%;
            background-repeat: no-repeat;
        }
    }

    &__body {
        display: block;
        padding: 20px 24px;
        border-top: 1px solid $color-lightgray;
        border-radius: 0 0 4px 4px;
        background-color: $color-white;
    }

    &.is-closed &__head::after {
        display: block;
        background-image: svg-load('assets/img/icon/plus.svg', fill=$color-green);
    }

    &.is-closed &__body {
        display: none;
    }

    &.is-opened &__head::after {
        display: block;
        background-image: svg-load('assets/img/icon/minus.svg', fill=$color-green);
    }

    &.is-opened &__body {
        display: block;
    }
}
