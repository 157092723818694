.chat {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $color-darkwhite;
    box-shadow: inset 12px 0 12px -12px rgba($color-black, 0.2);

    &__area {
        flex-grow: 1;
        overflow: auto;
        padding: 24px;
    }

    &__item {
        padding: 16px 0;
        margin-right: 32px;

        &:last-child {
            margin-bottom: 0;
        }

        &--own {
            margin-right: 0;
            margin-left: 32px;
        }

        &-head {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 8px;
        }

        &-person {
            font-size: 16px;
            font-weight: semibold;
        }

        &-date {
            font-size: 14px;
            font-weight: medium;
            color: $color-gray;

            &::before {
                content: '';
                display: inline-block;
                vertical-align: middle;
                width: 12px;
                height: 12px;
                margin-right: 4px;
                background: svg-load('assets/img/icon/clock.svg', fill=$color-gray) 50% 50% no-repeat;
            }
        }

        &-message {
            border: 1px solid $color-lightgray;
            border-radius: 4px;
            padding: 24px;
            background-color: $color-white;
            font-size: 16px;
            line-height: 1.4;
            font-weight: medium;
        }

        &--own &-message {
            border-bottom: 6px solid $color-yellow;
            border-radius: 4px 4px 8px 8px;
        }
    }

    &__bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-shrink: 0;
        border-top: 1px solid $color-lightgray;
        padding: 24px;
    }

    &__input {
        width: auto;
        flex-grow: 1;
    }

    &__button {
        position: relative;
        width: 64px;
        margin-left: 16px;
        flex-shrink: 0;

        &::after {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            width: 20px;
            height: 24px;
            margin: -12px 0 0 -8px;
            background-image: svg-load('assets/img/icon/send.svg', fill=$color-white);
        }

        &:active:not(:disabled)::after {
            margin: -11px 0 0 -7px;
        }
    }

    /* Vue.js transition */
    &.chat-enter-active,
    &.chat-leave-active {
        transition: opacity .5s;
    }

    &.chat-enter,
    &.chat-leave-active {
        opacity: 0;
    }

    &.chat-enter &__container,
    &.chat-leave-active &__container {
        transform: scale(1.1);
    }
}
