.scheme-select {
    &__list {
        @mixin main-grid;

        margin-bottom: -24px;
    }

    &__item {
        margin-bottom: 24px;
        border: 1px solid $color-lightgray;
        border-radius: 4px;
        padding: 24px;
        cursor: pointer;

        &:hover {
            box-shadow: 0 0 4px 0 $color-lightgray;
        }

        &.is-active {
            border-color: $color-darkgray;
            box-shadow: inset 0 0 0 3px $color-darkgray;
        }

        &:nth-child(3n+1) {
            grid-column: 1/5;
        }

        &:nth-child(3n+2) {
            grid-column: 5/9;
        }

        &:nth-child(3n+3) {
            grid-column: 9/13;
        }
    }

    &__header {
        margin-bottom: 24px;
        font-size: 16px;
        font-weight: semibold;
    }

    &__attrs {
        /* dummy */
    }

    &__attr {
        & + & {
            margin-top: 16px;
        }

        &-label {
            font-weight: semibold;
        }

        &-value {
            font-weight: medium;
            color: $color-darkgray;
        }
    }

    &__empty {
        /* dummy */
    }
}
