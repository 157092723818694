.app {
    position: relative;
    height: 100%;

    &__block {
        @mixin root-grid;

        position: relative;
        height: 100%;
    }

    &__aside {
        position: relative;
        grid-column: 1/2;
    }

    &__main {
        position: relative;
        grid-column: 2/3;
    }
}
