.news-list {
    &__list {
        @mixin main-grid;

        margin-bottom: -16px;
    }

    &__item {
        grid-column: 1/13;
        margin-bottom: 16px;
    }

    &--col &__item {
        &:nth-child(2n+1) {
            grid-column: 1/7;
        }

        &:nth-child(2n+2) {
            grid-column: 7/13;
        }
    }

    &__empty {
        /* dummy */
    }
}
