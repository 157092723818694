.info {
    &__field {
        & + & {
            margin-top: 16px;
        }
    }

    &__fieldset {
        & + & {
            margin-top: 32px;
        }
    }

    &__header {
        margin-bottom: 24px;
        font-size: 18px;
        font-weight: semibold;
    }
}
