.client-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border: 1px solid $color-gray;
    border-radius: 4px;

    &__head {
        flex-grow: 0;
    }

    &__body {
        flex-grow: 1;
    }

    &__foot {
        flex-grow: 0;
    }

    &__info {
        padding: 16px 32px 24px;
        border-bottom: 2px solid $color-yellow;

        &-lastname {
            font-size: 24px;
            line-height: 1.33;
            font-weight: medium;
        }

        &-firstname {
            font-size: 24px;
            line-height: 1.33;
        }

        &-phone {
            margin-top: 8px;
            font-size: 18px;
        }
    }

    &__doc {
        padding: 16px 32px;
        border-top: 1px solid $color-gray;

        &:first-child {
            border-top: none;
        }

        &-type {
            font-size: 16px;
            line-height: 1.33;
            font-weight: medium;
        }

        &-attrs {
            margin-top: 8px;
        }

        &-attr {
            display: inline;
            margin-right: 24px;
        }

        &-label {
            display: inline;
            margin-right: 4px;
            font-weight: medium;
            color: $color-darkgray;
        }

        &-value {
            display: inline;
            font-weight: semibold;
        }
    }

    &__panel {
        padding: 12px 32px;
        border-top: 1px solid $color-gray;

        &-item {
            font-size: 18px;
            text-align: center;

            & + & {
                margin-top: 8px;
            }
        }
    }
}
