.icon-select {
    &__list {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: -16px;
    }

    &__item {
        width: 80px;
        height: 80px;
        margin-bottom: 16px;
        margin-right: 16px;
        border: 1px solid $color-lightgray;
        border-radius: 4px;
        cursor: pointer;

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            box-shadow: 0 0 4px 0 $color-lightgray;
        }

        &.is-active {
            border-color: $color-darkgray;
            box-shadow: inset 0 0 0 2px $color-darkgray;
        }
    }

    &.is-valid &__item {
        border-color: $color-green;
        background-color: rgba($color-green, 0.03);

        &:hover {
            box-shadow: 0 0 4px 0 $color-green;
        }

        &.is-active {
            box-shadow: inset 0 0 0 2px $color-green;
        }
    }

    &.is-invalid &__item {
        border-color: $color-yellow;
        background-color: rgba($color-yellow, 0.03);

        &:hover {
            box-shadow: 0 0 4px 0 $color-yellow;
        }

        &.is-active {
            box-shadow: inset 0 0 0 2px $color-yellow;
        }
    }

    &.is-error &__item {
        border-color: $color-lightred;
        background-color: rgba($color-lightred, 0.03);

        &:hover {
            box-shadow: 0 0 4px 0 $color-lightred;
        }

        &.is-active {
            box-shadow: inset 0 0 0 2px $color-lightred;
        }
    }

    &.is-disabled &__item,
    &__item.is-disabled {
        opacity: 0.4;
        cursor: not-allowed;

        &:hover {
            box-shadow: none;
        }
    }

    &__icon {
        width: 80%;
        height: 80%;
        margin: 10% 0 0 10%;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: contain;
    }

    &__empty {
        /* dummy */
    }
}
