.main {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 24px;
    padding-bottom: 24px;
    background-color: $color-darkwhite;

    &__head {
        @mixin main-padding;

        flex-grow: 0;
        padding-bottom: 32px;
    }

    &__body {
        @mixin main-padding;

        flex-grow: 1;
    }

    &__foot {
        @mixin main-padding;

        padding-top: 32px;
        flex-grow: 0;
    }

    &__block {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &__section {
        & + & {
            margin-top: 32px;
        }
    }

    &__bar {
        @mixin main-grid;
    }

    &__cancel {
        grid-column: 1/7;
    }

    &__submit {
        grid-column: 7/13;
    }

    &__prev {
        grid-column: 1/5;
    }

    &__this {
        grid-column: 5/9;
    }

    &__next {
        grid-column: 9/13;
    }

    &__goto {
        display: inline-block;
        vertical-align: middle;
        width: 16px;
        height: 16px;
        margin-left: 16px;
        background: svg-load('assets/img/icon/edit.svg', fill=$color-lightblack) 50% 50% no-repeat;
    }
}
