.client-list {
    &__list {
        @mixin main-grid;

        margin-bottom: -24px;
    }

    &__item {
        margin-bottom: 24px;

        &:nth-child(2n+1) {
            grid-column: 1/7;
        }

        &:nth-child(2n+2) {
            grid-column: 7/13;
        }
    }
}
