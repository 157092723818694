*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    min-width: 100%;
    height: 100%;
    overflow-x: auto;
}

body {
    @mixin base;

    position: relative;
    min-width: 1280px;
    height: 100%;
    background-color: #eaeaea;
}

.h1 {
    @mixin h1;
}

.h2 {
    @mixin h2;
}

.h3 {
    @mixin h3;
}

.h4 {
    @mixin h4;
}

.p {
    @mixin p;
}

.ul {
    @mixin ul;
}

.ol {
    @mixin ol;
}

.hr {
    @mixin hr;
}

.a {
    @mixin a;
    @mixin a--color $color-base;

    &--green {
        @mixin a--color $color-green;
    }

    &--yellow {
        @mixin a--color $color-yellow;
    }

    &--red {
        @mixin a--color $color-red;
    }

    &--purple {
        @mixin a--color $color-purple;
    }

    &--darkgray {
        @mixin a--color $color-darkgray;
    }
}

.table {
    @mixin table;
}

.base {
    @mixin base;
}

.format {
    @mixin format;
}

.text {
    @mixin text;
}

.input {
    @mixin input;
}

.select {
    @mixin select;
}

.autocomplete {
    @mixin autocomplete;
}

.textarea {
    @mixin textarea;
}

.checkbox {
    @mixin checkbox;
}

.radiobox {
    @mixin radiobox;
}

.button {
    @mixin button;
    @mixin button--color $color-white, $color-lightblack;

    &--green {
        @mixin button--color $color-green, $color-white;
    }

    &--yellow {
        @mixin button--color $color-yellow, $color-white;
    }

    &--red {
        @mixin button--color $color-red, $color-white;
    }

    &--purple {
        @mixin button--color $color-purple, $color-white;
    }
}

.inpton {
    @mixin inpton;
    @mixin inpton--color $color-white, $color-lightblack;

    &--green {
        @mixin inpton--color $color-green, $color-white;
    }

    &--yellow {
        @mixin inpton--color $color-yellow, $color-white;
    }

    &--red {
        @mixin inpton--color $color-red, $color-white;
    }

    &--purple {
        @mixin inpton--color $color-purple, $color-white;
    }
}
