.news-item {
    border: 1px solid $color-lightgray;
    border-radius: 4px;
    box-shadow: 0 0 16px 0 rgba($color-lightblack, 0.1);
    background-color: $color-white;

    &__pic {
        font-size: 0;
    }

    &__img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
    }

    &__cont {
        padding: 24px 24px 16px 24px;
    }

    &--row&--pic {
        @mixin main-grid;

        grid-auto-flow: dense;
    }

    &--row&--pic &__pic {
        grid-column: 9/13;
    }

    &--row&--pic &__cont {
        grid-column: 1/9;
        padding-right: 0;
    }

    &--row&--pic &__img {
        height: 100%;
        border-radius: 0 4px 4px 0;
    }

    &--col&--pic &__img {
        width: 100%;
        border-radius: 4px 4px 0 0;
    }

    &__header {
        margin-bottom: 16px;
    }

    &__text {
        /* dummy */
    }

    &__panel {
        margin-top: 24px;

        &-item {
            display: inline-block;
            vertical-align: middle;

            & + & {
                margin-left: 16px;
            }
        }
    }

    &__label {
        padding: 4px 16px;
        border-radius: 2px;
        color: $color-white;
        background-color: $color-gray;

        &--info {
            color: $color-white;
            background-color: $color-blue;
        }

        &--warning {
            color: $color-white;
            background-color: $color-yellow;
        }

        &--danger {
            color: $color-white;
            background-color: $color-lightred;
        }
    }

    &__date {
        color: $color-gray;

        &::before {
            content: '';
            display: inline-block;
            vertical-align: middle;
            width: 12px;
            height: 12px;
            margin-right: 4px;
            background: svg-load('assets/img/icon/clock.svg', fill=$color-gray) 50% 50% no-repeat;
        }
    }
}
