@font-face {
    font-family: 'Raleway';
    src: local('Raleway Thin'), local('Raleway-Thin'),
        url('assets/fonts/ralewaythin.woff2') format('woff2'),
        url('assets/fonts/ralewaythin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'Raleway';
    src: local('Raleway Thin Italic'), local('Raleway-ThinItalic'),
        url('assets/fonts/ralewaythinitalic.woff2') format('woff2'),
        url('assets/fonts/ralewaythinitalic.woff');
    font-weight: 100;
    font-style: italic;
}
@font-face {
    font-family: 'Raleway';
    src: local('Raleway ExtraLight'), local('Raleway-ExtraLight'),
        url('assets/fonts/ralewayextralight.woff2') format('woff2'),
        url('assets/fonts/ralewayextralight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: 'Raleway';
    src: local('Raleway ExtraLight Italic'), local('Raleway-ExtraLightItalic'),
        url('assets/fonts/ralewayextralightitalic.woff2') format('woff2'),
        url('assets/fonts/ralewayextralightitalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
}
@font-face {
    font-family: 'Raleway';
    src: local('Raleway Light'), local('Raleway-Light'),
        url('assets/fonts/ralewaylight.woff2') format('woff2'),
        url('assets/fonts/ralewaylight.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Raleway';
    src: local('Raleway Light Italic'), local('Raleway-LightItalic'),
        url('assets/fonts/ralewaylightitalic.woff2') format('woff2'),
        url('assets/fonts/ralewaylightitalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'Raleway';
    src: local('Raleway Regular'), local('Raleway-Regular'),
        url('assets/fonts/ralewayregular.woff2') format('woff2'),
        url('assets/fonts/ralewayregular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Raleway';
    src: local('Raleway Italic'), local('Raleway-Italic'),
        url('assets/fonts/ralewayitalic.woff2') format('woff2'),
        url('assets/fonts/ralewayitalic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: 'Raleway';
    src: local('Raleway Medium'), local('Raleway-Medium'),
        url('assets/fonts/ralewaymedium.woff2') format('woff2'),
        url('assets/fonts/ralewaymedium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Raleway';
    src: local('Raleway Medium Italic'), local('Raleway-MediumItalic'),
        url('assets/fonts/ralewaymediumitalic.woff2') format('woff2'),
        url('assets/fonts/ralewaymediumitalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: 'Raleway';
    src: local('Raleway SemiBold'), local('Raleway-SemiBold'),
        url('assets/fonts/ralewaysemibold.woff2') format('woff2'),
        url('assets/fonts/ralewaysemibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Raleway';
    src: local('Raleway SemiBold Italic'), local('Raleway-SemiBoldItalic'),
        url('assets/fonts/ralewaysemibolditalic.woff2') format('woff2'),
        url('assets/fonts/ralewaysemibolditalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
}
@font-face {
    font-family: 'Raleway';
    src: local('Raleway Bold'), local('Raleway-Bold'),
        url('assets/fonts/ralewaybold.woff2') format('woff2'),
        url('assets/fonts/ralewaybold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Raleway';
    src: local('Raleway Bold Italic'), local('Raleway-BoldItalic'),
        url('assets/fonts/ralewaybolditalic.woff2') format('woff2'),
        url('assets/fonts/ralewaybolditalic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: 'Raleway';
    src: local('Raleway ExtraBold'), local('Raleway-ExtraBold'),
        url('assets/fonts/ralewayextrabold.woff2') format('woff2'),
        url('assets/fonts/ralewayextrabold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: 'Raleway';
    src: local('Raleway ExtraBold Italic'), local('Raleway-ExtraBoldItalic'),
        url('assets/fonts/ralewayextrabolditalic.woff2') format('woff2'),
        url('assets/fonts/ralewayextrabolditalic.woff') format('woff');
    font-weight: 800;
    font-style: italic;
}
@font-face {
    font-family: 'Raleway';
    src: local('Raleway Black'), local('Raleway-Black'),
        url('assets/fonts/ralewayblack.woff2') format('woff2'),
        url('assets/fonts/ralewayblack.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: 'Raleway';
    src: local('Raleway Black Italic'), local('Raleway-BlackItalic'),
        url('assets/fonts/ralewayblackitalic.woff2') format('woff2'),
        url('assets/fonts/ralewayblackitalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
}
