.pawn-select {
    &__list {
        @mixin main-grid;

        margin-bottom: -24px;
    }

    &__item {
        margin-bottom: 24px;
        border: 1px solid $color-lightgray;
        border-radius: 4px;
        padding: 32px 16px 16px;
        cursor: pointer;

        &:hover {
            box-shadow: 0 0 4px 0 $color-lightgray;
        }

        &.is-active {
            border-color: $color-darkgray;
            box-shadow: inset 0 0 0 3px $color-darkgray;
        }

        &:nth-child(3n+1) {
            grid-column: 1/5;
        }

        &:nth-child(3n+2) {
            grid-column: 5/9;
        }

        &:nth-child(3n+3) {
            grid-column: 9/13;
        }
    }

    &__icon {
        height: 130px;
        margin-bottom: 16px;
        background-position: 50% 50%;
        background-repeat: no-repeat;

        &--jewel {
            background-image: svg-load('assets/img/pawn-type/jewel.svg', fill=$color-gray);
        }

        &--product {
            background-image: svg-load('assets/img/pawn-type/product.svg', fill=$color-gray);
        }

        &--auto {
            background-image: svg-load('assets/img/pawn-type/auto.svg', fill=$color-gray);
        }
    }

    &__item.is-active &__icon {
        &--jewel {
            background-image: svg-load('assets/img/pawn-type/jewel.svg', fill=$color-darkgray);
        }

        &--product {
            background-image: svg-load('assets/img/pawn-type/product.svg', fill=$color-darkgray);
        }

        &--auto {
            background-image: svg-load('assets/img/pawn-type/auto.svg', fill=$color-darkgray);
        }
    }

    &__name {
        font-size: 18px;
        font-weight: medium;
        text-align: center;
    }

    &__empty {
        /* dummy */
    }
}
