.nav {
    &__header {
        @mixin aside-margin;

        margin-bottom: 16px;
        font-size: 14px;
        font-weight: semibold;
        text-transform: uppercase;
    }

    &__link {
        @mixin aside-padding;

        display: block;
        position: relative;
        padding-top: 16px;
        padding-bottom: 16px;
        font-size: 16px;
        text-decoration: none;
        color: $color-lightblack;
        background-position: $aside-margin 50%;
        background-repeat: no-repeat;

        &::before {
            display: none;
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 4px;
            height: 100%;
            background-color: $color-yellow;
        }

        &:hover {
            background-color: rgba($color-yellow, 0.1);
        }

        &--pawn {
            background-image: svg-load('assets/img/icon/diamond.svg', fill=$color-yellow);
        }

        &--search {
            background-image: svg-load('assets/img/icon/search.svg', fill=$color-yellow);
        }

        &--chat {
            background-image: svg-load('assets/img/icon/message.svg', fill=$color-yellow);
        }

        &--support {
            background-image: svg-load('assets/img/icon/service.svg', fill=$color-yellow);
        }

        &--exit {
            background-image: svg-load('assets/img/icon/exit.svg', fill=$color-red);
            color: $color-red;

            &:hover {
                background-color: rgba($color-red, 0.1);
            }
        }

        &.is-active,
        &.is-subactive {
            &::before {
                display: block;
            }
        }
    }

    &__span {
        margin-left: 32px;
    }
}
